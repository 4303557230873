import React, { useEffect, useState } from "react";
import "../../style/orderList.css";
import { ArrowLeftOutlined, DeleteOutlined, FormOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../redux-store/reducer";
import { AppDispatch } from "../../../redux-store/store";
import { setStoreBeatAction } from "../../../redux-store/action/storeActions";
import { Input } from "antd";
import previousPage from "utils/previousPage";
import DeleteItem from "../common/deleteItem";
import { deleteBeatService } from "services/storeService";

export default function BeatList() {
  const beatData = useSelector(state => state?.store?.storeBeat);
  const dispatch = useDispatch<AppDispatch>();
  const [beatDataList, setBeatDataList] = useState<any[]>([]);

  useEffect(() => {
    dispatch(setStoreBeatAction());
  }, []);

  useEffect(() => {
    setBeatDataList(beatData);
  }, [beatData])

  const searchStore = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    const FS = beatData.filter((item: any) =>
      (item?.beatName?.toLowerCase())?.includes(value.toLowerCase())
    );
    setBeatDataList(FS);
  };
  const [toggleDelete, setToggleDelete] = useState(false);
  const [beatName, setBeatName] = useState('');
  const [beatId, setBeatID] = useState('');
  const toggleHandler = (beatId: string, name: string) => {
    setToggleDelete(true);
    setBeatID(beatId);
    setBeatName(name)
  }
  return (
    <div>
      <header className="heading heading-container">
        <ArrowLeftOutlined onClick={previousPage} className="back-button" />
        <h1 className="page-title pr-18">Beats</h1>
      </header>
      <Link to="/admin/create-beat">
        <div className="addIcon">
          <PlusOutlined
            className="plusIcon"
          />
        </div>
      </Link>
      <main>
        <div className="searchStoreType">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search Beat"
            onChange={searchStore}
            className="searchContainer"
          />
        </div>
        <DeleteItem
          toggle={toggleDelete}
          name={beatName}
          itemsId={beatId}
          deleteService={deleteBeatService}
          closeModal={(e: any) => {
            setToggleDelete(e);
          }} />
        <div>
          <table className="storeCatTable">
            <thead>
              <tr>
                <th className="createvisittable">Beat Id</th>
                <th className="createvisittable">Name</th>
                <th className="createvisittable">Area</th>
                <th className="createvisittable"></th>
                <th className="createvisittable"></th>
              </tr>
            </thead>
            <tbody className="fs-13">
              {beatDataList && beatDataList?.length > 0 && beatDataList?.map((data, index) => {
                const { beatId, beatName, area, createdAt } = data;
                return (
                  <tr key={index}>
                    <td>{beatId}</td>
                    <td>{beatName}</td>
                    <td>{area}</td>
                    <td>
                      <Link to={`/admin/create-beat?beatId=${beatId}`} className='linkDefault'>
                        <FormOutlined />
                      </Link>
                    </td>
                    <td>
                      <DeleteOutlined
                        onClick={() => toggleHandler(beatId, beatName)}
                        className="deleteIcon" />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}
