import * as Yup from "yup";

const loginSchema = Yup.object({
  phone: Yup
    .string()
    .required("Please Enter User Id"),
  // password: Yup.string().required("Please enter a  password"),
  password: Yup.string().required('Please enter your password')
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must Contain atleast 4 Characters."
  // )
});

// const forgotPasswordSchema = Yup.object({
//   email: Yup.string()
//     .email("Invalid email format")
//     .required("Please enter your email address"),
// });
const forgotPasswordSchema = Yup.object({
  phone: Yup.string().required('Please Enter Phone no.'),
  dob: Yup.string().required('Please Enter Date of Birth'),
});

const resetPasswordSchema = Yup.object({
  password: Yup.string().required("Please enter new password"),
  confirmPassword: Yup.string()
  .required('Please enter confirm password')
  .oneOf([Yup.ref('password')], 'Passwords must match'),


});


const storeCategorySchema = Yup.object({
  categoryName: Yup.string().required('Please Enter Store Category Name')
});

const beatSchema = Yup.object({
  beatName: Yup.string().required('Please Enter Beat Name'),
  area: Yup.string().required('Please Enter Area'),
  store: Yup.array().required('Please Select Store')
});

const targetSchema = Yup.object({
  SSMId: Yup.string().required('Please Enter Sales Executive'),
  amountTarget: Yup.string().required('Please Enter Amount'),
  storeTarget: Yup.string().required('Please Select Store Count.')
});

const yearTarget = Yup.object({
  amountTarget: Yup.string().optional(),
});


const productSchema = Yup.object({
  productName: Yup.string().required('Please Enter Product Name'),
  mrp: Yup.string().required('Please Enter MRP'),
  rlp: Yup.string().required('Please Select RLP'),
  brandId: Yup.string().required('Please Select Brand'),
  categoryId: Yup.string().optional(),
  caseQty: Yup.string().required('Please Enter Case Quantity'),
  discountType: Yup.string().optional(),
  discountValue: Yup.string().optional(),
  isDiscountActive: Yup.string().optional(),
  isActive: Yup.string().optional(),
  isFocused: Yup.boolean().optional(),
});

const visitSchema = Yup.object({
  beat: Yup.string().required('Please Select Beat'),
  visitDate: Yup.string().required('Please Select Date'),
  emp_id: Yup.string().required('Please Select Sales Person'),
  store: Yup.array().required('Please Select Sales Person')
});

const productBrandSchema = Yup.object({
  name: Yup.string().required('Please Enter Brand Name')
});

const productCategorySchema = Yup.object({
  name: Yup.string().required('Please Enter Category Name'),
  parentId: Yup.string().optional(),
  
});

const updateCategorySchema = Yup.object({
  name: Yup.string().required('Please Update Category Name'),
  parentId: Yup.string().optional()

});


const updateBrandSchema = Yup.object({
  name: Yup.string().required('Please Update Brand Name')
});


const createStoreSchema = Yup.object({
  "storeName": Yup.string().required('Field required'),
  "uid": Yup.string().required('Field required'),
  "emp_id": Yup.string().optional().nullable(),
  "assignToRetailor": Yup.string().optional().nullable(),
  "storeType": Yup.string().required('Field required'),
  "lat": Yup.string().optional().nullable(),
  "long": Yup.string().optional().nullable(),
  "addressLine1": Yup.string().required('Field required'),
  "addressLine2": Yup.string().optional(),
  "townCity": Yup.string().required('Field required'),
  "state": Yup.string().required('Field required'),
  "email": Yup.string().optional(),
  "pinCode": Yup.string().required('Field required').matches(/^[0-9]{6}$/, 'Pin Code must be exactly 6 digits and contain only numbers'),
  "ownerName": Yup.string().required('Field required'),
  "mobileNumber": Yup.string().required('Field required').matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits and contain only numbers'),
  "openingTime": Yup.string().required('Field required'),
  "closingTime": Yup.string().required('Field required'),
  "openingTimeAmPm": Yup.string().required('Field required'),
  "closingTimeAmPm": Yup.string().required('Field required'),
  "isPremiumStore": Yup.boolean(),
  "isActive": Yup.boolean(),
  "beat": Yup.string().optional()
})

const createNewUserSchema =(isUpdate:any) => Yup.object({
  "firstname": Yup.string().required('Field required'),
  "lastname": Yup.string().optional(),
  "email": Yup.string().optional(),
  "password": isUpdate ? Yup.string().optional(): Yup.string().required('Field required'),
  "address": Yup.string().optional(),
  "city": Yup.string().optional(),
  "state": Yup.string().optional(),
  "pincode": Yup.string().optional(),

  // "age": Yup.string().required('Field required'),
  "dob": Yup.string().optional(),

  "phone": Yup.string().required('Field required').matches(/^[0-9]{10}$/, 'Must be exactly 10 digits'),
  // "zone": Yup.string().required('Field required'),
  "joining_date": Yup.string().required('Field required'),
  "managerId": Yup.string().required('Field required'),
  "role": Yup.string().required('Field required'),
  // "learningRole": Yup.string().required('Field required')
})

const addCourseSchema = Yup.object({
  courseName: Yup.string().required('Please Enter Course Name'),
  description: Yup.string().required('Please Enter Description'),
  isActive: Yup.string().required('Please Select'),
  dueDate: Yup.string().required('Please Enter Due Date'),
  // videoLink: Yup.string().required('Please Enter Video Link'),
  targetAudience: Yup.array().required('Please Enter target Audience'),
  quizDuration: Yup.string().required('Please Enter Quiz Duration'),
  launchedDate: Yup.string().required('Please Enter Launch Date'),
});


const addQuizSchema = Yup.object({
  question: Yup.string().required('Please Enter Question'),
  marks: Yup.string().required('Please Enter Marks'),
  answer: Yup.string().required('Please Select Answer'),
  option1: Yup.string().required('Please Enter Option'),
  option2: Yup.string().required('Please Enter Option'),
  option3: Yup.string().required('Please Enter Option'),
  option4: Yup.string().required('Please Enter Option'),
});

const noOrderReasonSchema = Yup.object({
  description: Yup.string().required('Please Enter Description')
});

const colourSchema = Yup.object({
  name: Yup.string().required('Please Enter Colour Name')
});
const sizeSchema = Yup.object({
  name: Yup.string().required('Please Enter Size Name')
});
const featureSchema = Yup.object({
  name: Yup.string().required('Please Enter Feature Name'),
  status: Yup.boolean().required('Please Select Status')

});
const roleSchema = Yup.object({
  name: Yup.string().required('Please Enter Role Name')
});
export {
  loginSchema,
  storeCategorySchema,
  beatSchema,
  productBrandSchema,
  createStoreSchema,
  updateBrandSchema,
  createNewUserSchema,
  visitSchema,
  productSchema,
  productCategorySchema,
  updateCategorySchema,
  addCourseSchema,
  addQuizSchema,
  targetSchema,
  yearTarget,
  forgotPasswordSchema,
  resetPasswordSchema,
  noOrderReasonSchema,
  colourSchema,
  sizeSchema,
  featureSchema,
  roleSchema
}