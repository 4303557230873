import HookFormInputField from 'component/HookFormInputField'
import React, { useEffect, useState } from 'react'
import '../../style/createBeat.css'
import { beatSchema } from "../../../utils/formValidations";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderAction } from "../../../redux-store/action/appActions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, message } from "antd";
import { createBeatService, getBeatByIdService, updateBeatService } from "services/storeService";
import { AppDispatch } from 'redux-store/store';
import { getStoreActions } from 'redux-store/action/storeActions';
import HookFormSelectField from 'component/HookFormSelectField';
import { useLocation, useNavigate } from 'react-router-dom';
import previousPage from 'utils/previousPage';
import { ArrowLeftOutlined } from '@ant-design/icons';

type FilterDetails = {
  storeType: string;
  // isUnbilled: boolean;
};

type Pagination = {
  pageNumber: number;
  pageSize: number;
}
export default function CreateBeat() {
  const filterDetails: FilterDetails = {
    storeType: "all",
    // isUnbilled: false,
  }

  const pagination: Pagination = {
    pageNumber: 1,
    pageSize: 100
  }

  const redirect = useNavigate();
  const storeData = useSelector((state: any) => state.store.storeList)
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const beatId: string | null = searchParams.get('beatId');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch(getStoreActions(filterDetails, pagination));
  }, [])

  const {
    control,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "all",
    resolver: yupResolver(beatSchema),
    defaultValues: {
      beatName: "",
      area: "",
      store: []
    }
  })

  useEffect(() => {
    async function getproductBrandData() {
      try {
        if (beatId) {
          setIsLoading(true);
          const res = await getBeatByIdService(beatId);
          setIsLoading(false);
          setValue("beatName", res?.data?.data?.beatName)
          setValue("area", res?.data?.data?.area)
          setValue("store", [...res?.data?.data?.store])
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
    getproductBrandData();
  }, [beatId])

  const onSubmit = async (values: any) => {
    const { beatName, area, store } = values;
    if (beatId) {
      try {
        dispatch(setLoaderAction(true));
        const response = await updateBeatService({ beatId: Number(beatId), beatName, area, store });
        dispatch(setLoaderAction(false));
        if (response?.data?.status === 200) {
          message.success("Updated Successfully")
          redirect("/admin/beat")
        } else {
          message.error("Something Went Wrong");
        }
      } catch (error: any) {
        dispatch(setLoaderAction(false));
        message.error("Something Went Wrong");
      }
    } else {
      try {
        dispatch(setLoaderAction(true));
        const response = await createBeatService({ beatName, area, store });
        dispatch(setLoaderAction(false));
        if (response) {
          message.success("Added Successfully")
          redirect("/admin/beat")
        }
      } catch (error: any) {
        dispatch(setLoaderAction(false));
        message.error("Something Went Wrong");
      }
    }
  };

  return (
    <div>
      <header className="heading heading-container">
        <ArrowLeftOutlined onClick={previousPage} className="back-button" />
        <h1 className="page-title pr-18">{beatId ? "Update Beat" : "Create Beat"}</h1>
      </header>
      <main className='content CbeatContainer'>
        <Form
          className="add-store-form createbeat"
          onFinish={handleSubmit(onSubmit)}
          autoComplete="off">
          <HookFormInputField
            control={control}
            type="text"
            name="beatName"
            placeholder="Enter Beat name"
            label={"Beat Name"}
            required
          />
          <HookFormInputField
            control={control}
            type="text"
            name="area"
            placeholder="Search Area"
            label={"Area"}
            required
          />
          <HookFormSelectField
            control={control}
            type="text"
            name="store"
            placeholder="Select Store"
            label={"Add Store"}
            showSearch
            mode="multiple"
            allowClear
            defaultValue={[]}
            optionData={
              storeData?.map((data: any) => ({
                label: data?.storeName,
                value: data?.storeId,
              }))
            }
            required
          />
          <div className="take-orders-summary">
            <div
              className=" orders-btn">
              <Button onClick={() => redirect(-1)}>Cancel</Button>
              <button type="submit" className="btn-save">
                Save
              </button>
            </div>
          </div>
        </Form>
      </main>
    </div>
  )
}