import { API_ENDPOINTS } from "app-constants";
import { apiService } from "./apiService";
import { IGetOrderCollectionDataRes } from "types/Order";
import { ICreatePaymentReq, IGetOnlinePaymentRes, IPaymentCaptureReq } from "types/Payment";

function addPaymentByCashService(data: ICreatePaymentReq) {
    return apiService.post(API_ENDPOINTS.addPaymentByCash, data)
}

function addPaymentByOnlineService(data: IGetOnlinePaymentRes) {
    return apiService.post(API_ENDPOINTS.addPaymentByOnline, data)
}

function paymentCaptureByRazorpayService(data: IPaymentCaptureReq) {
    return apiService.post(API_ENDPOINTS.paymentCaptureByRazorpay, data)
}


function getPaymentRecordByOrderIdService(orderId: string) {
    return apiService.get<IGetOrderCollectionDataRes>(`${API_ENDPOINTS.getPaymentRecordByOrderId}/${orderId}`)
}


export {
    addPaymentByCashService,
    addPaymentByOnlineService,
    paymentCaptureByRazorpayService,
    getPaymentRecordByOrderIdService
}